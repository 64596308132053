<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <CarrotTitle title="교재출고 요청하기">
      - 필요한 내부교재는 <span class="bold">[내부교재 출고요청]</span> 버튼
      클릭 후 등록해 주시기 바랍니다.<br />
      - 필요한 외부교재는
      <span class="bold">[전자결재 - 외부교재 구입신청서]</span>를 통해 기안해
      주시기 바랍니다.<br />
      - 교재명을 클릭하면 요청상세내용을 보실 수 있습니다.<br />
      - 내부교재 출고요청 내용 수정 및 삭제는 ‘요청‘ 상태일 때만 가능합니다.<br />
      - 반납요청 시 <span class="bold">[반납요청]</span> 버튼 클릭 후 반납하고자
      하는 교재 수량을 입력해 주세요.
    </CarrotTitle>
    <div class="board">
      <div class="mt-40">
        <div>
          <table class="table-row table-serach">
            <tbody>
              <tr>
                <th width="180">완료/취소일</th>
                <td>
                  <carrot-date-picker
                    v-model.sync="bbs.sdate"
                    class="w-110px dp-inblock"
                  ></carrot-date-picker>
                  ~
                  <carrot-date-picker
                    v-model.sync="bbs.edate"
                    class="w-110px dp-inblock"
                  ></carrot-date-picker>
                </td>
                <th width="180">교재명</th>
                <td>
                  <carrot-book-title
                    v-model:bookname.sync="bbs.bookname"
                    v-model="bbs.book"
                    class="w-80per"
                  ></carrot-book-title>
                </td>
              </tr>
              <tr>
                <th width="180">등록일/출고희망일</th>
                <td>
                  <carrot-date-picker
                    v-model.sync="bbs.sdate2"
                    class="w-110px dp-inblock"
                  ></carrot-date-picker>
                  ~
                  <carrot-date-picker
                    v-model.sync="bbs.edate2"
                    class="w-110px dp-inblock"
                  ></carrot-date-picker>
                </td>
                <th width="180">요청자</th>
                <td>
                  <carrot-team
                    :idx_office="bbs.office"
                    v-model="bbs.team"
                    class="w-40per"
                  ></carrot-team>
                  <carrot-staff
                    :idx_team="bbs.team"
                    orderby="easc"
                    v-model="bbs.staff"
                    class="w-40per ml-10"
                  ></carrot-staff>
                </td>
              </tr>
            </tbody>
          </table>
          <button @click="bbs.doInit" class="btn-default float-right mt-10">
            검색
          </button>
          <div class="clear"></div>

          <div class="mt-40 mb-20">
            <select
              @change="bbs.doInit"
              v-model="bbs.btype"
              class="w-100px float-left"
            >
              <option value="">구분 전체</option>
              <option value="내부">내부</option>
              <option value="외부">외부</option>
            </select>
            <select
              @change="bbs.doInit"
              v-model="bbs.state"
              class="w-130px float-left ml-20"
            >
              <option value="">출고현황 전체</option>
              <option value="CANCEL">취소</option>
              <option value="DONE">완료</option>
              <option value="REQUEST">요청</option>
            </select>
            <button
              v-if="bbs.isAuth == true"
              @click="bbs.downExcel"
              class="btn-default float-left ml-20"
            >
              엑셀 다운로드
            </button>

            <button @click="bbs.addRental" class="btn-default float-right">
              내부교재 출고요청
            </button>
            <button
              v-if="bbs.isAuth == true"
              @click="bbs.doDone"
              class="btn-default float-right mr-20"
            >
              출고완료
            </button>
            <button
              v-if="bbs.isAuth == true"
              @click="bbs.doCancel"
              class="btn-default float-right mr-20"
            >
              출고취소
            </button>
            <div class="clear"></div>
          </div>

          <table class="table-col table-auto">
            <!-- <colgroup>
                            <col width="40">
                            <col width="60">
                            <col width="100">
                            <col width="100">
                            <col width="80">
                            <col width="*">
                            <col width="80">
                            <col width="80">
                            <col width="80">
                            <col width="120">
                            <col width="80">
                            <col width="100">
                        </colgroup> -->
            <thead>
              <tr>
                <th>
                  <label
                    ><input
                      type="checkbox"
                      @change="bbs.checkAll"
                      v-model="bbs.isAll"
                  /></label>
                </th>
                <th>No</th>
                <th>등록일</th>
                <th>출고희망일</th>
                <th width="50">구분</th>
                <th width="*">교재명</th>
                <th>수량</th>
                <th>유/무상</th>
                <th>출고현황</th>
                <th>
                  매니저
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby != 'nameasc' && bbs.orderby != 'namedesc'"
                    @click="bbs.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby != 'nameasc' && bbs.orderby != 'namedesc'"
                    @click="bbs.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby == 'nameasc'"
                    @click="bbs.doSearchOrderBy('namedesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby == 'namedesc'"
                    @click="bbs.doSearchOrderBy('nameasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>
                  팀
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby != 'teamasc' && bbs.orderby != 'teamdesc'"
                    @click="bbs.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby != 'teamasc' && bbs.orderby != 'teamdesc'"
                    @click="bbs.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-down"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby == 'teamasc'"
                    @click="bbs.doSearchOrderBy('teamdesc')"
                    class="fas fa-arrow-up"
                  ></span>
                  <span
                    style="cursor: pointer"
                    v-if="bbs.orderby == 'teamdesc'"
                    @click="bbs.doSearchOrderBy('teamasc')"
                    class="fas fa-arrow-down"
                  ></span>
                </th>
                <th>완료/취소일</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(irow, i) in bbs.list" :key="i">
                <td>
                  <label v-if="irow.is_return == 'N'"
                    ><input
                      type="checkbox"
                      v-model="bbs.selected"
                      :value="irow.idx"
                  /></label>
                </td>
                <td>{{ irow.num }}</td>
                <td>{{ irow.wdate }}</td>
                <td>{{ irow.request_date }}</td>
                <td>{{ irow.btype }}</td>
                <td class="txt-left">
                  <span v-if="irow.state == 'CANCEL'" class="line-clamp-1">{{
                    irow.bookname
                  }}</span>
                  <span
                    v-else
                    @click="bbs.showRental(i)"
                    class="pointer line-clamp-1"
                    >{{ irow.bookname }}</span
                  >
                </td>
                <td>{{ irow.qty }}</td>
                <td>{{ irow.is_paid == "Y" ? "유상" : "무상" }}</td>
                <td>
                  <span v-if="irow.state == 'REQUEST'" class="color-1"
                    >요청</span
                  >
                  <span v-if="irow.state == 'DONE'">완료</span>
                  <span v-if="irow.state == 'CANCEL'">취소</span>
                </td>
                <td>
                  <p class="line-clamp-1">
                    {{ irow.staff_ename }} ({{ irow.staff_kname }})
                  </p>
                </td>
                <td>
                  <p class="line-clamp-1">
                    {{ irow.team_name }}
                  </p>
                </td>
                <td>
                  <span v-if="irow.state == 'DONE'">{{ irow.done_date }}</span>
                  <span v-if="irow.state == 'CANCEL'">{{
                    irow.cancel_date
                  }}</span>
                </td>
              </tr>
              <tr v-if="bbs.total == 0">
                <td colspan="10">등록된 출고 정보가 없습니다.</td>
              </tr>
            </tbody>
          </table>
          <CarrotPaging
            v-if="bbs.isLoaded == true"
            :total="bbs.total"
            :list_per_page="bbs.rows"
            v-model="bbs.page"
            @change="bbs.doSearch"
          ></CarrotPaging>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import Swal from "sweetalert2";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
import CarrotBookTitle from "@/components/common/CarrotBookTitle.vue";
import CarrotTeam from "@/components/common/CarrotTeam.vue";
import CarrotStaff from "@/components/common/CarrotStaff.vue";

export default {
  layout: "ResourceManagement",
  components: {
    CarrotDatePicker,
    CarrotBookTitle,
    CarrotTeam,
    CarrotStaff,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const bbs = reactive({
      isLoaded: false,
      isAuth: false,
      isMounted: false,
      isAll: false,
      selected: [],
      page: 1,
      rows: 50,

      sdate: "",
      edate: "",
      sdate2: "",
      edate2: "",
      bookname: "",
      book: 0,
      company: 0,
      office: "99999",
      team: 0,
      staff: 0,

      state: "",
      btype: "",
      orderby: "",

      list: [],
      total: 0,
      doSearchOrderBy: (orderby) => {
        bbs.orderby = orderby;
        bbs.doSearch();
      },
      checkAll: () => {
        if (bbs.isAll == true) {
          for (let i = 0; i < bbs.list.length; i++) {
            let irow = bbs.list[i];
            bbs.selected.push(irow.idx);
          }
        } else {
          bbs.isAll = false;
          bbs.selected = [];
        }
      },

      downExcel: () => {
        location.href =
          "https://api.carrotians.net/excel/textbook_rental_list" +
          "?sdate=" +
          bbs.sdate +
          "&edate=" +
          bbs.edate +
          "&sdate2=" +
          bbs.sdate2 +
          "&edate2=" +
          bbs.edate2 +
          "&book=" +
          bbs.book +
          "&company=" +
          bbs.company +
          "&team=" +
          bbs.team +
          "&staff=" +
          bbs.staff +
          "&state=" +
          bbs.state +
          "&btype=" +
          bbs.btype +
          "&orderby=" +
          bbs.orderby;
      },

      doDone: () => {
        if (bbs.selected.length <= 0) {
          Swal.fire({
            title: "출고완료",
            text: "완료할 교재를 선택하세요.",
          });
          return;
        }

        Swal.fire({
          title: "출고완료",
          text:
            "총 " + bbs.selected.length + "건의 교재 출고를 완료하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed == true) {
            let params = {
              selected: bbs.selected,
            };
            axios
              .post("/rest/resourceManagement/doneBookRental", params)
              .then((res) => {
                if (res.data.err == 0) {
                  // toast.error("완료하였습니다.");
                  Swal.fire({
                    title: "출고완료",
                    text:
                      bbs.selected.length +
                      "건 중 " +
                      res.data.cnt +
                      "건 출고가 완료되었습니다.",
                  }).then(() => {
                    bbs.doInit();
                  });
                } else if (res.data.err == 200) {
                  let msg = [];
                  let canceled = res.data.canceled;
                  for (let i = 0; i < bbs.list.length; i++) {
                    let irow = bbs.list[i];
                    if (canceled.indexOf(irow.idx) >= 0) {
                      msg.push("[" + irow.title + "]");
                    }
                  }
                  msg.join("<br>");
                  Swal.fire({
                    title: "출고완료",
                    text:
                      msg + " 출고 요청이 재고 부족으로 출고할 수 없습니다.",
                  });
                } else {
                  if (res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
          }
        });
      },

      doCancel: () => {
        if (bbs.selected.length <= 0) {
          Swal.fire({
            title: "출고취소",
            text: "취소할 교재를 선택하세요.",
          });
          return;
        }

        Swal.fire({
          title: "출고취소",
          text:
            "총 " + bbs.selected.length + "건의 교재 출고를 취소하시겠습니까?",
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed == true) {
            let params = {
              selected: bbs.selected,
            };
            axios
              .post("/rest/resourceManagement/cancelBookRental", params)
              .then((res) => {
                if (res.data.err == 0) {
                  // toast.error("취소하였습니다.");
                  Swal.fire({
                    title: "출고취소",
                    text:
                      bbs.selected.length +
                      "건 중 " +
                      bbs.selected.length +
                      "건 출고가 취소되었습니다.",
                  }).then(() => {
                    bbs.doInit();
                  });
                } else {
                  if (res.data.err_msg) toast.error(res.data.err_msg);
                }
              });
          }
        });
      },

      setStorage: () => {
        sessionStorage.setItem(
          "TextbookRentalList",
          JSON.stringify({
            page: bbs.page,
            rows: bbs.rows,
            sdate: bbs.sdate,
            edate: bbs.edate,
            sdate2: bbs.sdate2,
            edate2: bbs.edate2,
            book: bbs.book,
            company: bbs.company,
            team: bbs.team,
            staff: bbs.staff,
            state: bbs.state,
            btype: bbs.btype,
            orderby: bbs.orderby,
          })
        );
      },

      showRental: (i) => {
        let o = bbs.list[i];

        bbs.setStorage();

        router.push({
          name: "ResourceManagement-TextbookRentalView-idx",
          params: {
            idx: o.idx,
          },
        });
      },

      addRental: () => {
        bbs.setStorage();

        router.push({
          name: "ResourceManagement-TextbookRentalAdd",
        });
      },

      doInit: () => {
        bbs.page = 1;
        bbs.isAll = false;
        bbs.selected = [];
        bbs.doSearch();
      },

      doSearch: () => {
        if (bbs.isMounted == false) return;
        let params = {
          page: bbs.page,
          rows: bbs.rows,
          sdate: bbs.sdate,
          edate: bbs.edate,
          sdate2: bbs.sdate2,
          edate2: bbs.edate2,
          book: bbs.book,
          company: bbs.company,
          team: bbs.team,
          staff: bbs.staff,
          state: bbs.state,
          btype: bbs.btype,
          orderby: bbs.orderby,
        };
        axios
          .get("/rest/resourceManagement/getBookRentalList", { params: params })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;

              bbs.isAuth = res.data.is_auth == "Y" ? true : false;

              bbs.isAll = false;
              bbs.selected = [];

              bbs.isLoaded = true;

              sessionStorage.setItem("TextbookRentalList", "");
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },
    });

    onMounted(() => {
      // Mounted
      bbs.isMounted = true;

      let ss = sessionStorage.getItem("TextbookRentalList");
      if (ss) {
        ss = JSON.parse(ss);
        if (ss.page) bbs.page = ss.page;
        if (ss.rows) bbs.rows = ss.rows;
        if (ss.sdate) bbs.sdate = ss.sdate;
        if (ss.edate) bbs.edate = ss.edate;
        if (ss.sdate2) bbs.sdate2 = ss.sdate2;
        if (ss.edate2) bbs.edate2 = ss.edate2;
        if (ss.bookname) bbs.bookname = ss.bookname;
        if (ss.title) bbs.title = ss.title;
        if (ss.team) bbs.team = ss.team;
        if (ss.staff) bbs.staff = ss.staff;
        if (ss.state) bbs.state = ss.state;
        if (ss.btype) bbs.btype = ss.btype;
        if (ss.orderby) bbs.orderby = ss.orderby;
      }

      bbs.doSearch();
    });

    return { bbs };
  },
};
</script>

<style lang="scss" scoped>
.sub-wrap .con-wrap .table-col thead tr th,
.sub-wrap .con-wrap .table-col tbody tr td {
  word-break: normal;
}
</style>
